<template>
  <div class="d-flex flex-column fill-height" v-if="!loading">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <apexchart type="line" height="300" :options="incidentsChart.chartOptions" :series="incidentsChart.chartData">
        </apexchart>
      </v-col>
    </v-row>
    <v-responsive max-height="calc(100% - 332px)">
      <div class="d-flex align-center align-self-center" style="flex: 1; width: 100%">
        <div style="width: 100%">
          <v-text-field class="filter-field" v-model="search" append-icon="mdi-magnify" label="Buscar" single-line
            hide-details></v-text-field>
          <v-data-table :headers="headers" :items="items" :search="search">
            <template v-slot:item.actions="{ item }">
              <v-btn icon v-for="(action, index) in actions" :key="index" @click="action.action(item)">
                <v-icon>mdi-crosshairs-gps</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-responsive>
  </div>
  <div v-else>Cargando..</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import ApiService from '@/util/api.service';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    store: Object,
    actions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      items: [],
      loading: true,
      width: 800,
      height: 600,
      incidents_chart: {},
      headers: [
        {
          text: 'Identificador',
          align: 'start',
          filterable: true,
          value: 'uniqueId',
        },
        { text: 'Estatus', filterable: true, value: 'status' },
        { text: 'Tipo', filterable: true, value: 'tipoIncidente' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    this.incidentsStore = this.store;
    this.items = this.store.getRecords();
    this.store.subscribe((item) => {
      const index = this.items.findIndex((it) => it.id === item.id);
      if (index === -1) {
        this.items.push(item);
      } else {
        this.items[index] = item;
      }
    });
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      ApiService({
        url: '/incidents/chart/categorysummary',
        method: 'get',
      })
        .then((resp) => {
          const ret = {};
          const proc = {
            days: [],
            medico: [],
            seguridad: [],
            proteccion_civil: [],
            servicios_publicos: [],
          };
          resp.forEach((item) => {
            const fecha = item.created.substring(0, 10);
            if (!ret[fecha]) {
              ret[fecha] = {
                medico: 0,
                seguridad: 0,
                servicios_publicos: 0,
                proteccion_civil: 0,
              };
            }
            ret[fecha][item.category] = item.accounting;
          });
          Object.keys(ret).forEach((key) => {
            proc.days.push(key);
            proc.medico.push(ret[key].medico);
            proc.seguridad.push(ret[key].seguridad);
            proc.servicios_publicos.push(ret[key].servicios_publicos);
            proc.proteccion_civil.push(ret[key].proteccion_civil);
          });
          this.incidents_chart = proc;
        })
        .catch((err) => {
          console.error('LoadIncidents', { err });
          window.VMA.showError({ title: 'Ocurrió un error al cargar los incidentes' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    centrar(item) {
      this.$store.dispatch('map/setSelectedItem', item);
      window.VMA.map.setCenter(item.latitude, item.longitude, 13);
    },
    showComponent() {
      this.show = true;
    },
    hideComponent() {
      this.show = false;
    },
  },
  computed: {
    left() {
      return window.innerWidth / 2 - this.width / 2;
    },
    top() {
      return window.innerHeight / 2 - this.height / 2;
    },
    incidentsChart() {
      return {
        chartData: [
          {
            name: 'Seguridad Publica',
            data: this.incidents_chart.seguridad,
          },
          {
            name: 'Emergencias Medicas',
            data: this.incidents_chart.medico,
          },
          {
            name: 'Servicios Públicos',
            data: this.incidents_chart.servicios_publicos,
          },
          {
            name: 'Protección Civil',
            data: this.incidents_chart.proteccion_civil,
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ['#0088a4', '#638a86', '#6f57a5', '#c17d19'],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '16px',
            },
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: 'Incidentes',
            align: 'center',
            margin: 25,
            style: {
              fontSize: '18px',
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 1,
          },
          xaxis: {
            categories: this.incidents_chart.days,
            title: {
              text: 'Dia',
              style: {
                fontSize: '14px',
              },
            },
          },
          yaxis: {
            title: {
              text: 'Incidentes registrados',
              style: {
                fontSize: '14px',
              },
            },
            labels: {
              style: {
                fontSize: '14px',
              },
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'center',
            floating: true,
            offsetY: -25,
            offsetX: -5,
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-field {
  padding: 20px;
}
</style>
